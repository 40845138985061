import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'

export const getImageStyles = cva(['overflow-hidden'], {
  variants: {
    width: {
      'w-1/2': 'w-1/2',
      'w-1/3': 'w-1/3',
      'w-2/3': 'w-full xl:w-2/3',
      'w-1/6': 'w-1/6 -mx-[.125rem]'
    },
    desktopWidth: {
      'w-1/2': 'w-full md:w-1/2',
      'w-1/3': 'w-full md:w-1/3',
      'w-2/3': 'w-full md:w-2/3',
      'w-1/6': 'w-full md:w-1/6 md:-mx-[.125rem]'
    }
  }
})

export type GetImageStyles = VariantProps<typeof getImageStyles>
